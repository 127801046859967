import {navigateToOriginPage} from "~/utils/loginSignup/loginSignup";

export default defineNuxtRouteMiddleware((to, from) => {
  const isLogged = useIsLogged()
  if (!isLogged?.value) {
    return true
  }

  return navigateToOriginPage()
})
